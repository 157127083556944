import Vue from 'vue';

const prodURL =
  window.location.origin.includes('indsiders.com') &&
  !window.location.origin.includes('dev') &&
  !window.location.origin.includes('homolog');
const homologURL = window.location.origin.includes('homolog');
const localhostURL = window.location.origin.includes('localhost');

if (prodURL) {
  Vue.prototype.$mySfcLink = 'https://mysfc.indsiders.com/';
  Vue.prototype.$myConnectLink = 'https://myconnect.indsiders.com/';
  Vue.prototype.$myConfigLink = 'https://myconfig.indsiders.com/';
} else if (homologURL) {
  Vue.prototype.$mySfcLink = 'https://mysfc-homolog.indsiders.com/';
  Vue.prototype.$myConnectLink = 'https://myconnect-homolog.indsiders.com/';
  Vue.prototype.$myConfigLink = 'https://myconfig-homolog.indsiders.com/';
} else if (localhostURL) {
  Vue.prototype.$mySfcLink = 'http://localhost:8081/';
  Vue.prototype.$myConnectLink = 'http://localhost:8082/';
  Vue.prototype.$myConfigLink = 'http://localhost:8083/';
} else {
  Vue.prototype.$mySfcLink = 'https://mysfc-dev.indsiders.com/';
  Vue.prototype.$myConnectLink = 'https://myconnect-dev.indsiders.com/';
  Vue.prototype.$myConfigLink = 'https://myconfig-dev.indsiders.com/';
}
