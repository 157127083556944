<template>
  <b-overlay :show="_flagShowLoading">
    <div id="app">
      <div
        :class="[
          'layout-d',
          {
            'layout-login': isAuthenticationPage,
            'layout-app': !isAuthenticationPage,
            desktop: desktop && !isAuthenticationPage,
            mobile: !desktop && !isAuthenticationPage
          }
        ]"
      >
        <div :class="[desktop ? 'sticky-menu' : '']">
          <Menu
            v-if="!isAuthenticationPage"
            :desktop="desktop"
            :device="device"
          />
        </div>

        <div class="page">
          <router-view />
          <Footer v-if="!isAuthenticationPage" />
        </div>
      </div>
    </div>

    <template #overlay>
      <div class="text-center d-flex flex-column align-items-center">
        <LoadSpinner class="overlay-svg" />
      </div>
    </template>
  </b-overlay>
</template>

<script>
  import Menu from '@/components/Menu/index.vue';
  import { BOverlay } from 'bootstrap-vue';
  import Footer from '@/components/footer/index.vue';

  import { mapActions, mapMutations } from 'vuex';

  export default {
    name: 'AppRoot',
    components: {
      LoadSpinner: () => import('@/assets/icons/loading.svg'),
      BOverlay,
      Menu,
      Footer
    },
    data: () => {
      return {
        userEmail: '',
        prefix: '',
        desktop: window.innerWidth > 768,
        isAuthenticationPage: true,
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 425 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile'
      };
    },
    computed: {
      userInfocookie() {
        return this.$cookies.get('userInfo');
      }
    },
    watch: {
      userInfocookie: {
        handler() {
          this.verifyConnection(true);
        },
        deep: true
      }
    },
    beforeCreate() {
      const selectedLanguage = localStorage.getItem('language');
      const availableLanguages = Object.keys(this.$i18n.messages);
      const isValid = availableLanguages.some((item) => item === selectedLanguage);

      if (selectedLanguage && isValid) {
        this.$i18n.locale = selectedLanguage;
      }
    },
    created() {
      window.addEventListener('resize', this.handleResize);
      this.verifyConnection();
    },
    mounted() {
      this.setUserStore();
      this.checkIfIsAuthenticationPage();
      this.checkRouter();
      const domain = window.location.href.includes('indsiders.com');
      this.$cookies.set(
        'modalNoSites',
        { mysfc: false, myconnect: false },
        null,
        null,
        domain ? 'indsiders.com' : null
      );
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      ...mapActions('userStore', ['setUser']),
      ...mapMutations('appStore', ['UPDATE_PERMISSIONS_MODULES']),
      handleResize() {
        this.desktop = window.innerWidth > 768;
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 425 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      },
      verifyConnection(item) {
        const userInfo = this.$cookies.get('userInfo');

        if (userInfo) {
          this.UPDATE_PERMISSIONS_MODULES(userInfo.userData.permissions);

          this.userEmail = userInfo.userData.email;
          let tenant = this.userEmail.split('@');
          this.prefix = this.prefix ? this.prefix : tenant[1].split('.')[0];
          const infoAccount = {
            guid: userInfo.userData.guid,
            employee_id: userInfo.userData.employee_id,
            first_name: userInfo.userData.first_name,
            last_name: userInfo.userData.last_name,
            job_role: userInfo.userData.job_role,
            photo: userInfo.userData.photo
          };

          this.setUser({
            ...userInfo.userData,
            name: `${infoAccount.first_name} ${infoAccount.last_name}`,
            role: infoAccount.job_role,
            picture: infoAccount.photo
          });

          localStorage.setItem('accessToken', userInfo.accessToken);
          localStorage.setItem('userData', JSON.stringify(userInfo.userData));
          localStorage.setItem('prefix', this.prefix);
          localStorage.setItem('language', 'pt-BR');

          if (
            localStorage.getItem('loggedAccountsInfo') &&
            !JSON.parse(localStorage.getItem('loggedAccountsInfo')).find(
              (item) => item.guid == userInfo.userData.guid
            )
          ) {
            let guidAtual = JSON.parse(localStorage.getItem('loggedAccountsInfo'));
            guidAtual.unshift(infoAccount);
            localStorage.setItem('loggedAccountsInfo', JSON.stringify(guidAtual));
          }

          !localStorage.getItem('loggedAccountsInfo') &&
            localStorage.setItem('loggedAccountsInfo', JSON.stringify([infoAccount]));

          const diffMultipleAccount = this.$route.name !== 'multiple-account';

          if (item && diffMultipleAccount) {
            this.$router.push({ name: 'home' }).catch(() => {});
          }
          this.disabledButton = false;
          diffMultipleAccount && this._hideLoading();
        } else if (this.$route.name !== 'reset-password') {
          localStorage.clear();
          this.UPDATE_PERMISSIONS_MODULES(null);
          const domain = window.location.href.includes('indsiders.com');
          this.$cookies.remove('userInfo', null, domain ? 'indsiders.com' : null);
          this.$router.push('/login').catch(() => {});
        }
      },
      checkIfIsAuthenticationPage() {
        const authenticationPages = [
          'login',
          'forgot',
          'reset-password',
          'reset-pin',
          'multiple-account'
        ];
        this.isAuthenticationPage = authenticationPages.includes(this.$router.currentRoute.name);
      },
      setUserStore() {
        const user = JSON.parse(localStorage.getItem('userData'));
        if (user) {
          this.setUser({
            ...user,
            name: `${user.first_name} ${user.last_name}`,
            role: user.job_role,
            picture: user.photo
          });
        }
      },
      checkRouter() {
        this.$router.afterEach(
          // eslint-disable-next-line no-unused-vars
          (to, _) =>
            (this.isAuthenticationPage =
              to.name === 'login' ||
              to.name === 'forgot' ||
              to.name === 'reset-password' ||
              to.name === 'reset-pin')
        );
      }
    }
  };
</script>

<style lang="scss">
  @import url('./assets/styles/global.scss');
  @import url('./assets/styles/reset.scss');

  body {
    touch-action: manipulation !important;
    &:has(.full-wide.open) {
      overflow-y: hidden;
      .page {
        display: none !important;
      }
    }
  }

  .layout-app > .page {
    min-height: calc(100dvh - 115px);
    padding-bottom: 67px;
    max-width: 1117px;
    margin: 0 auto;
  }

  .sticky-menu {
    position: sticky;
    top: 0;
    max-height: 100vh;
    z-index: 999;
  }
</style>
