<template>
  <div>
    <Navbar
      :user="user"
      :device="device"
      @logout="logout"
    />
  </div>
</template>
<script>
import Navbar from './Navbar/index.vue';

export default {
  name: 'SidebarMenu',
  components: {
    Navbar
  },
  props: {
    device: {
      type: String,
      required: 'desktop'
    }
  },
  data() {
    return {
      user: { first_name: '', last_name: '', photo: '' }
    };
  },
  methods: {
    logout() {
      this.$emit("logout")
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('userData'));
  }
};
</script>
