<template>
  <div>
    <div id="navbar">
      <div class="side">
        <div
          v-show="device === 'mobile'"
          class="toggle sidebar-open-button"
          @click="toggleFullScreenMenu"
        >
          <HamburgerIcon
            v-show="!open"
            class="open-sidebar-icon"
          />
          <CloseIcon
            v-show="open"
            class="open-sidebar-icon"
          />
        </div>
        <div class="logo">
          <Logo class="logo-icon" />
        </div>
      </div>
      <div class="profile">
        <ExtraItem
          v-if="device === 'mobile'"
          id="extra"
          :items="navItems"
          :name="$t('Menu.Items.Navigation')"
          :title="$t('Menu.Items.Navigation')"
          :border="false"
          :variant="variant"
          :hidden-tooltip="true"
          tooltip-placement="bottom"
        />

        <div
          v-if="device !== 'mobile'"
          class="nav-items"
        >
          <NavItem
            item-id="myind-navbar"
            :name="$t('Menu.Items.Home')"
            variant="short"
            :selected="currentPage === 'home'"
            :icon="icons.HomeIcon"
            tooltip-placement="bottom"
            :hidden-tooltip="device !== 'desktop'"
            @click="() => redirect('home')"
          />

          <NavItem
            :name="$t('Menu.Items.Support')"
            variant="short"
            stroke-hover
            :selected="currentPage === 'support'"
            :icon="icons.HeadsetIcon"
            data-redirect="support"
            tooltip-placement="bottom"
            :hidden-tooltip="device !== 'desktop'"
            @click="() => redirect('support')"
          />
        </div>
        <div
          id="profile_trigger_navbar"
          tabindex="0"
          class="picture"
        >
          <img
            v-if="user.photo"
            :src="user.photo"
            alt="User photo"
          />
          <AvatarPlaceholder
            v-else
            size="30px"
            :text="picturePlaceholder"
          />
        </div>
      </div>
      <Popover
        :items="items"
        target="profile_trigger_navbar"
        :title="$t('Menu.Titles.Account')"
        :second-title="$t('Menu.Languages.Title')"
        :user="user"
        is-profile-popover
        has-language
        placement="bottom"
        custom-position="navbar"
        triggers="focus"
      />
      <Menu
        v-show="open && device === 'mobile'"
        :user="user"
        :device="device"
        :close-dropdown="open"
        :is-open="open"
        wide
        @close="closeFullscreenMenu"
      />
    </div>
  </div>
</template>

<script>
  import Logo from '@/assets/icons/logo-full.svg';
  import ExtraItem from '../Extra';
  import HomeIcon from '@/assets/icons/home-icon.svg';
  import HamburgerIcon from '@/assets/icons/hamburger-icon.svg';
  import CloseIcon from '@/assets/icons/close-icon.svg';
  import HeadsetIcon from '@/assets/icons/headset-icon.svg';
  import ArrowDown from '@/assets/icons/arrow-down.svg';
  import ProfileIcon from '@/assets/icons/profile-icon.svg';
  import ExitIcon from '@/assets/icons/exit-icon.svg';

  import Menu from '../Sidebar/index.vue';
  import Popover from '../Popover.vue';
  import NavItem from '../NavItem.vue';
  import Tooltip from '../Tooltip.vue';

  import AvatarPlaceholder from './components/AvatarPlaceholder.vue';

  const ICONS = {
    ProfileIcon,
    ExitIcon,
    HomeIcon,
    HeadsetIcon,
    CloseIcon
  };

  export default {
    name: 'NavbarApp',
    components: {
      Logo,
      HomeIcon,
      HamburgerIcon,
      CloseIcon,
      HeadsetIcon,
      ArrowDown,
      ExtraItem,
      Menu,
      Tooltip,
      Popover,
      AvatarPlaceholder,
      NavItem
    },
    props: {
      user: {
        type: Object
      },
      device: {
        type: String,
        default: 'desktop'
      }
    },
    data() {
      var self = this;
      return {
        open: false,
        icons: ICONS,
        navItems: [
          {
            title: 'Menu.Items.Home',
            icon: ICONS.HomeIcon,
            click: () => this.redirect('home')
          },
          {
            title: 'Menu.Items.Support',
            icon: ICONS.HeadsetIcon,
            click: () => this.redirect('support')
          }
        ],
        items: [
          {
            title: 'Menu.Items.Account',
            icon: ICONS.ProfileIcon,
            click: () => this.redirect('account')
          },
          {
            title: 'Menu.Items.Exit',
            icon: ICONS.ExitIcon,
            click: () => self._logout()
          }
        ]
      };
    },
    computed: {
      picturePlaceholder() {
        const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? '';
        return `${firstLetter(this.user.first_name)}${firstLetter(this.user.last_name)}`;
      },
      currentPage() {
        return this.$route.name;
      }
    },
    methods: {
      toggleFullScreenMenu() {
        this.open = !this.open;
      },
      closeFullscreenMenu() {
        this.open = false;
      },
      redirect(page) {
        if (this.wide) {
          this.$emit('close');
        }
        if (this.$route.name === page) return;

        this.$router.push({ name: page }).catch(() => {});
      },
      addVisibleNavItems() {
        const sidebar = this.$refs.sidebar;
        const navItemGroups = [this.$refs.generalGroup, this.$refs.sites];

        // Tamanho da viewport
        const viewportHeight = window.innerHeight - 100;
        // Posição do topo do sidebar em relação ao documento
        const sidebarTop = sidebar.getBoundingClientRect().top;
        // Itens visíveis e itens que ultrapassam o limite da viewport
        const visibleNavItems = [];
        const overflowNavItems = [];

        for (const group of navItemGroups) {
          const groupTitle = group.title;
          const navItems = group.$children;
          for (const item of navItems) {
            const itemRect = item.$el.getBoundingClientRect();
            const itemTop = itemRect.top - sidebarTop;
            if (itemTop >= 0 && itemTop + itemRect.height + 30 <= viewportHeight) {
              // O item está visível na viewport
              visibleNavItems.push({
                group: groupTitle,
                navItem: item
              });
            } else {
              // O item ultrapassa o limite da viewport
              overflowNavItems.push({
                group: groupTitle,
                name: item.name,
                icon: item.icon,
                click: () => this.redirect(item.$el.dataset.redirect)
              });
            }
          }
        }

        // Atualizar a lista de itens visíveis e itens que ultrapassam a viewport
        this.visibleNavItems = visibleNavItems;
        this.overflowNavItems = overflowNavItems;
      }
    }
  };
</script>

<style lang="scss" scoped>
  #navbar {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);
    border-bottom: 1px solid #4c4541;
    background: rgba(26, 28, 25, 0.5);
    backdrop-filter: blur(25px);

    align-items: center;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backdrop-filter: blur(25px);
      backdrop-filter: blur(25px);
      z-index: -1;
    }
  }

  #navbar-breadcrumbs {
    padding: 0 2px;
    border-top: 1px solid #eee;
    background-color: #fff;
  }

  .nav-items {
    display: flex;
    align-items: center;
  }

  .side {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    .toggle {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-right: 1px solid #4c4541;
      svg {
        fill: #998f8a;
      }
      &:hover {
        background-color: #ffede1 !important;
        svg {
          fill: #974900 !important;
        }
      }
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      height: 100%;
      padding-left: 16px;
      svg {
        width: 107px;
        height: 30px;
      }
    }
  }

  .profile {
    cursor: pointer;
    border-left: 1px solid #4c4541;
    height: 100%;
    padding: 0 7px 0 0;
    display: flex;
    flex-direction: row;

    .my-ind,
    .support {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 0;
      .myind-icon,
      .support-icon {
        width: 16px;
        height: 16px;
        svg {
          fill: #4c4541;
        }
      }
    }
    .user {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      align-items: flex-end;
      width: 93px;

      h3,
      h2 {
        margin: 0;
        padding: 0;
        text-align: left;
      }

      h3 {
        color: #4c4541;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h2 {
        color: #998f8a;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .picture {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 9px;

      &:hover {
        border-radius: 6px;
        background: rgba(151, 73, 0, 0.05);
      }

      &:active {
        background: rgba(151, 73, 0, 0.16);
      }
    }

    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 100%;
    }

    .arrow-down {
      height: 100%;
      width: 16px;
      display: flex;
      align-items: center;
      svg {
        fill: #998f8a;
      }
    }
  }

  .sidebar-open-button {
    padding: 16px;
    .open-sidebar-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
    }
  }

  .logo-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow-down-icon {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 480px) {
    #navbar {
      .side {
        .logo {
          padding-left: 12px;
        }
      }
    }
  }
</style>
