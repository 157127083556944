<template>
  <b-popover
    :custom-class="`navigation_popover ${customPosition}`"
    :triggers="triggers"
    :target="target"
    :placement="placement"
    :offset="offset"
    :show.sync="show"
    boundary="viewport"
  >
  <header v-show="title">{{ title }}</header>
    <div 
      v-for="(item, index) in items"
      :key="index"  
      :class="[verifyCurrentPage(item.title) ? 'selected' : '']">
      <button
        :key="`menu-item-${item.name + index}`"
        @click="handleItemClick(item)"
      >
        <div :class="['icon', item.title.includes('Support') ? 'support-icon' : '']" v-if="item.icon">
          <component :is="item.icon" />
        </div>
        {{ $t(item.title) }}
      </button>
  </div>
  </b-popover>
</template>

<script>
  import HomeIcon from '@/assets/icons/home-icon.svg';
  import { BPopover } from 'bootstrap-vue';
  export default {
    name: 'PopOver',
    components: { HomeIcon, BPopover },
    props: {
      items: {
        type: Array,
        required: true
      },
      placement: {
        type: String,
        default: 'right'
      },
      title: {
        type: String
      },
      triggers: {
        type: String,
        default: 'focus'
      },
      target: {
        type: String,
        required: true
      },
      offset: {
        type: Number,
        default: 0
      },
      customPosition: {
        type: String,
        default: null
      }
    },
    data: () => ({ show: false }),
    computed: {
      filteredItems() {
        return this.items.reduce((result, item) => {
          const { group, ...rest } = item;
          if (!result[group]) {
            result[group] = [];
          }
          result[group].push(rest);
          return result;
        }, {});
      }
    },
    methods: {
      handleItemClick(item) {
        item.click();
        this.show = false;
      },
      verifyCurrentPage(name) {
        const currentPage = this.$route.name;
        const lastItem = name.split(".").slice(-1);
        return currentPage.includes(lastItem[0].toLowerCase()) ? true : false;
      }
    }
  };
</script>

<style lang="scss">
  .navigation_popover {
    .arrow {
      display: none !important;
    }
    .popover-body {
      padding: 0 !important;
      width: inherit !important;
      border: none !important;
    }
    width: 180px !important;

    &.navbar {
      top: -3px !important;
      left: 40px !important;
    }

    &.sidebar {
      top: -20px !important;
    }
  }
</style>

<style lang="scss" scoped>
  .icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      fill: #CFC4BE
    }
  }
  .popover {
    display: flex;
    width: 100%;
    padding: 8px 0px;
    border-radius: 6px;
    border: none;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: #1A1C19;
    box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.20), 0px 5px 22px 0px rgba(0, 0, 0, 0.12), 0px 12px 17px 0px rgba(0, 0, 0, 0.14);

    header {
      padding: 12px 21px;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
    }

    button {
    outline: 0;
    background: transparent;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 0;
    border-right: 0;
    cursor: pointer;

    width: 100%;
    padding: 12px 21px;

    display: flex;
    flex-direction: row;
    gap: 9px;

    font-size: 14px;
    font-weight: 400;
    color: #CFC4BE;

    user-select: none;

    &:hover {
      border-top: 1px solid #974900;
      border-bottom: 1px solid #974900;
      background: rgba(151, 73, 0, 0.05);
      color: #FFF;
      .icon path {
        fill: #fff;
      }
      .icon.exit-icon path {
        stroke: #fff;
      }
    }

    &:active {
      border-top: 1px solid #974900;
      border-bottom: 1px solid #974900;
      background:rgba(151, 73, 0, 0.16);
      color: #FFF;
      .icon path {
        fill: #fff;
      }
      .icon.exit-icon path {
        stroke: #fff;
      }
    }
  }
  .selected {
    background: #974900;
    button {
      color: #fff;
    }
    .icon path {
      fill: #fff;
    }
    .icon.exit-icon path {
      stroke: #fff;
    }
  }
}
</style>
