<template>
  <h2 class="cdl__subtitle">
    <slot />
  </h2>
</template>

<script>
  export default {
    name: 'CardLayoutSubtitle'
  };
</script>

<style lang="scss" scoped>
  .cdl__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding: 8px 0 0 0;
    color: #998f8a;
  }

  @media (max-width: 485px) {
    .cdl__subtitle {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
</style>
