export default {
  namespaced: true,
  state: {
    flagShowLoading: false,
    prefix: '',
    permissions: null
  },
  getters: {
    flagShowLoading: (state) => state.flagShowLoading,
    prefix: (state) => state.prefix
  },
  mutations: {
    UPDATE_FLAG_SHOW_LOADING: (state, payload) => (state.flagShowLoading = payload),
    UPDATE_PREFIX: (state, payload) => (state.prefix = payload),
    UPDATE_PERMISSIONS_MODULES(state, val) {
      state.permissions = val;
    }
  },
  actions: {
    showLoading({ commit }) {
      commit('UPDATE_FLAG_SHOW_LOADING', true);
    },
    hideLoading({ commit }) {
      commit('UPDATE_FLAG_SHOW_LOADING', false);
    },
    removePrefix({ commit }) {
      commit('UPDATE_PREFIX', '');
    }
  }
};
