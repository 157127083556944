<template>
  <div>
    <div
      id="menu-bar"
      ref="sidebar"
      :class="[isOpen ? 'open' : '', wide ? 'wide' : '', device === 'mobile' ? 'full-wide' : '']"
    ></div>
  </div>
</template>

<script>
  // Icons
  import CloseIcon from '@/assets/icons/x-icon.svg';

  // Components

  export default {
    name: 'MenuBar',
    components: {
      // Icons
      CloseIcon

      // Components
    },
    props: {
      wide: {
        type: Boolean,
        default: false
      },
      device: {
        type: String,
        default: 'desktop'
      },
      closeDropdown: {
        type: Boolean
      },
      isOpen: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        prefix: this.$router.currentRoute.params.prefix
      };
    },
    computed: {
      variant() {
        return this.isOpen ? 'full' : 'short';
      },
      language() {
        return this.$i18n.locale;
      }
    },
    watch: {
      language() {
        this.$nextTick(() => {
          this.addVisibleNavItems();
        });
      },
      selectedUnit() {
        this.device === 'mobile' && this.$emit('close');
      }
    },
    created() {
      window.addEventListener('resize', this.addVisibleNavItems);
    },
    destroyed() {
      window.removeEventListener('resize', this.addVisibleNavItems);
    }
  };
</script>

<style lang="scss" scoped>
  .side-items {
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .close-icon {
    width: 12px;
    height: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-full-close-btn {
    outline: 0;
    border: 1px solid transparent;
    background: transparent;
    cursor: pointer;

    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -khtml-border-radius: 50%;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;

    border-radius: 500px;
    border: 1px solid #cfc4be;
    background: #fff;

    svg {
      fill: #4c4541;
    }

    &:hover {
      border: 1px solid #974900;
    }

    &:active {
      border: 1px solid #e1730b;
    }
  }
  .toggle-icon {
    width: 10px;
    height: 10px;
  }

  #menu-bar {
    position: fixed !important;
    width: 80px;
    height: 100vh;
    padding: 16px 0;

    transition: all 0.6s;

    background: rgba(26, 28, 25, 0.5);
    backdrop-filter: blur(25px);
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);

    display: flex;
    flex-direction: column;
    gap: 16px;

    &.open {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      padding-bottom: 60px;
    }
    .settings-list {
      border-bottom: 1px solid #4c4541;
      &.collapse-sidebar-open {
        background-color: transparent;
      }
    }
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-full {
    padding: 23px 16px;
    text-align: left;
  }

  .user-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
  }

  .open {
    width: 260px !important;
    gap: 0 !important;
  }

  .wide {
    position: fixed !important;
    top: 49px !important;
    left: 0 !important;
    width: 100vw !important;
    z-index: 6 !important;
    overflow-y: auto !important;
  }
</style>
