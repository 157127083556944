<template>
  <!-- Full size variant -->
  <div
    v-if="variant === 'full'"
    :id="id"
    :class="['nav-item-full', open ? 'open' : '', id == currentLanguage && !dropdown ? 'current-language' : '']"
    @click="handleClickFull"
  >
    <slot class="icon-full" name="icon" />
    <span>{{ name }}</span>
    <div v-if="dropdown" :class="['dropdown', open ? 'flipped' : '']">
      <div class="icon-dropdown">
        <ArrowRight />
      </div>
    </div>
  </div>

  <!-- Compacted size variant -->
  <div v-else :id="id" class="nav-item-short" @click="$emit('click')">
    <div class="icon-short">
      <slot name="icon" />
    </div>
  </div>
</template>

<script>
  import ArrowRight from '@/assets/icons/arrow-right.svg';
  export default {
    components: { ArrowRight },
    props: {
      id: {
        type: Number
      },
      name: {
        type: String,
        required: true
      },
      variant: {
        type: String,
        required: true
      },
      dropdown: {
        type: Boolean,
        default: false
      },
      open: {
        type: Boolean,
        default: false
      },
      currentLanguage: {
        type: Number,
      }
    },
    methods: {
      handleClickFull() {
        this.$emit('update:open', !this.open);
        this.$emit('click');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .flipped {
    transform: rotate(90deg);
    transform-origin: center;
  }
  .icon-dropdown {
    width: 16px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: #998f8a;
    }
  }
  .icon-full,
  .icon-short {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-short {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
  .nav-item-full {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    padding: 8px 16px;
    gap: 8px;
    justify-content: space-between;

    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    cursor: pointer;
    user-select: none;

    span {
      font-family: Raleway;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex: 1;
      color: #998f8a;
    }

    .icon-dropdown svg {
      path {
        fill: #998f8a;
      }
    }

    &:hover{
      border-top: 1px solid #974900;
      border-bottom: 1px solid #974900;
      background: rgba(151, 73, 0, 0.05);
      span {
        color: #FFF;
      }

      .icon-dropdown svg {
        path {
          fill: #fff;
        }
      }
    }

    &:active,
    &.open {
      border-top: 1px solid #974900;
      border-bottom: 1px solid #974900;
      background:rgba(151, 73, 0, 0.16);
      span {
        color: #FFF;
      }
      .icon-dropdown svg {
      path {
        fill: #fff;
      }
    }
    }
    &.current-language {
      background-color: #974900 !important;
      span {
        color: #FFF;
      }
    }
  }

  .nav-item-short {
    padding: 6px 12px;
    border-radius: 5px;
    max-width: max-content;

    cursor: pointer;
    border: 1px solid transparent;

    min-width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    margin: 0 auto;

    &:hover {
      background-color: #ffede2;
      span {
        color: #974900;
      }
      .icon-dropdown svg {
        path {
          fill: #974900;
        }
      }
    }

    &:active,
    &.open {
      background-color: #ffdbc4;
      color: #974900;
    }

    &.selected {
      background: #974900;
    }
  }
</style>
