<template>
  <div class="cdl__logo-c">
    <Logo class="cdl__logo" />
  </div>
</template>

<script>
  import Logo from '@/assets/icons/indsiders-logo.svg';

  export default {
    name: 'ForgotCardLogo',
    components: {
      Logo
    }
  };
</script>

<style lang="scss" scoped>
  .cdl__logo-c {
    display: flex;

    .cdl__logo {
      width: 155px;
      height: 24px;
    }
  }
</style>
